
<template>
  <div
    id="forgotp"
    style="min-height: calc(100vh - 20px)"
    class="forgotp-page"
    tag="section"
  >
    <b-row>
      <div class="forgotp-box" no-body>
        <div class="forgotp-wrapper">
          <img src="@/assets/images/venue_booking/logo.svg" id="logo_image_f" />
           <b-overlay :show="login_emailshow" rounded="sm">
          <b-form  id="email_login_form" v-if="email_login_flag == 1" @submit.prevent="handleSubmit">
            <h2>Forgot Password</h2>
            <h6>Enter your email to reset your password </h6>
            <br>
            <b-form-group>
              <b-form-input
                id="txt-email"
                type="email"
                placeholder="Enter Email"
                class="mb-3"
                v-model="$v.form.email.$model"
                :state="validateState('email')"
              ></b-form-input>
              <b-form-invalid-feedback
                id="pehrs-1-live-feedback"
                v-if="$v.form.email.$error"
              >
                <div v-if="!$v.form.email.required">
                  Please enter your email
                </div>
                <div v-if="!$v.form.email.email">
                  Please enter valid email Ex. abc@gmail.com
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
             <b-alert :show="loginfail_a" variant="danger" class="bt-alert">
              <a  class="alert-link d-flex align-items-center">
                <b-button variant="danger" disabled>
                  <i class="mdi mdi-alert-circle"></i>
                </b-button>
                {{login_fail}}
              </a>
            </b-alert>
            <b-form-group>
              <b-button type="submit" variant="primary" block class="mt-2">
                <i class="fas fa-envelope">&nbsp;SUBMIT EMAIL</i>
              </b-button>
            </b-form-group>
            <div class="mt-2">Back to&nbsp;<b-link to="/login" class="link">Login?</b-link>
            </div>
          </b-form>
           </b-overlay>
           
          <b-overlay :show="otp_show" rounded="sm">
          <b-form
            id="login_otp_form"
            @submit.prevent="handleSubmitOtp"
            v-if="otp_flag == 1"
          >
            <h6>Check your Inbox and enter OTP</h6>
            <br />
            <b-form-group>
              <b-form-input
                id="txt-otp"
                type="text"
                placeholder="Enter OTP"
                class="mb-3"  
                v-model="otpform.otp"
                :state="validateStateOtp('otp')"
              ></b-form-input>
               <b-form-invalid-feedback
                id="pehrs-1-live-feedback"
                v-if="$v.otpform.otp.$error"
              >
                <div v-if="!$v.otpform.otp.required">
                  Please enter your OTP
                </div>
                 <div v-if="!$v.otpform.otp.minLength || !$v.otpform.otp.maxLength || !$v.otpform.otp.numeric">
                  Please enter valid OTP
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
               <b-alert :show="otpfail_a" variant="danger" class="bt-alert">
              <a  class="alert-link d-flex align-items-center">
                <b-button variant="danger" disabled>
                  <i class="mdi mdi-alert-circle"></i>
                </b-button>
                {{otp_fail}}
              </a>
            </b-alert>
            <b-form-group>
              <b-button type="submit" variant="primary" block class="mt-2">
                SUBMIT OTP
              </b-button>
            </b-form-group>

            <div class="mt-3">
              <b-link @click="resendOTP()" class="link" v-if="resend_link_active === true"> Resend OTP </b-link>
               <b-link class="link" disabled v-if="resend_link_active === false" style="color: #b1abab"> Resend OTP </b-link>
            </div>
          </b-form>
          </b-overlay>
        </div>
      </div>
    </b-row>
  </div>
</template>
    

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, email , minLength , maxLength , numeric} from "vuelidate/lib/validators";

export default {
  name: "Boxedforgotp",
  mixins: [validationMixin],
  data: () => ({
    form: {
      email: "",
    },
     otpform: {
      otp: "",
    },
     email_login_flag: 1,
    username_login_flag: 0,
    otp_flag: 0,
    flag: "",
    status_flag: localStorage.getItem("status_flag"),
    login_fail: "",
    otp_fail: "",
    loginfail_a: false,
    otpfail_a: false,
    login_emailshow: false,
    otp_show: false,
    resend_link_active : true,
  }),
  computed: {},
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
    otpform:{
      otp: {
        required,
         minLength: minLength(4),
         maxLength: maxLength(4),
         numeric
      }
    }
  },
  methods: {
    validateState(email) {
      const { $dirty, $error } = this.$v.form[email];
      return $dirty ? !$error : null;
    },
      validateStateOtp(otp) {
      const { $dirty, $error } = this.$v.otpform[otp];
      return $dirty ? !$error : null;
    },
    handleSubmit() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.login_emailshow = true; 
        this.flag = "email";
        axios
          .post("/api/forgot_password", {
            flag: this.flag,
            email: this.form.email,
  
          })
          .then((resp) => {
            if (resp.data.status) {
              this.login_emailshow = false;
              this.loginfail_a = false; 
              this.flag = "";
              localStorage.setItem("email", this.form.email);
              this.email_login_flag = 0;
              this.otp_flag = 1;
            } else {
             this.login_emailshow = false;
             this.loginfail_a = true; 
             this.login_fail = resp.data.message;
            }
          });
      }
    },
       handleSubmitOtp() {
       this.$v.otpform.$touch();
      if (!this.$v.otpform.$anyError) {
      this.otp_show = true;
      this.flag = "otp";
      axios
        .post("/api/forgot_password", {
          flag: this.flag,
          email: this.form.email,
          otp: this.otpform.otp,
        })
        .then((resp) => {
          if (resp.data.status) {
            this.otp_show = false;
            this.otpfail_a = false; 
            localStorage.clear();
            this.flag = "";
            this.email_login_flag = 0;
            this.otp_flag = 1;

          
            this.$router.push("/send_link");
          
          } else {
            this.otp_show = false;
            this.otpfail_a = true; 
            this.otp_fail = resp.data.message;
          }
        });
      }
    },
      resendOTP() {
      const email = localStorage.getItem("email");
      this.resend_link_active = false;
      axios
        .post("/api/resend_otp_forgot_password", {
          email: email,
        })
        .then((resp) => {
        if (resp.data.status) {
           this.$root.$refs.app.showToast(
            "success",
            resp.data.message
          );
          this.resend_link_active = true;
        } else {
          this.$root.$refs.app.showToast(
            "danger",
            resp.data.message
          );
        }
      });
    },
  },
};
</script>
<style scoped>
.forgotp-page {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#logo_image_f {
   width: 180px;
  /* margin-left: 90px; */
  /* height: 60px; */
  padding-bottom: 15px;
}
.forgotp-wrapper {
  text-align: center;
}
.forgotp-box {
  background: #fff;
  max-width: 400px;
  width: 100%;
  padding: 30px;
  margin: 8% auto;
}
@media screen and (max-width: 475px) {
  .forgotp-box {
    max-width: 100%;
    margin: 0 30px;
  }
}
</style>